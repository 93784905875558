import React from "react"
import { motion, AnimatePresence } from "utils/framer-motion"
import { ScaleUp } from "components/animations/scaleUp"
import { useModal } from "context/modal"

export const ModalWrapperVariants = {
    open: { opacity: "1" },
    closed: { opacity: "0" }
}

export const ModalOverlayVariants = {
    open: {
        opacity: "0.6",
        transition: {
            when: "beforeChildren"
        }
    },
    closed: {
        opacity: "0",
        transition: {
            when: "afterChildren"
        },
    }
}

export const ModalVariants = {
    open: {
        opacity: "1",
        y: "0",
        transition: {
            delay: "0.2"
        }
    },
    closed: {
        opacity: "0",
        y: "50%"
    }
}

const Modal = ({ children, onClose, modalClassName, ...props }) => {
    return (
        <AnimatePresence>
            <motion.div
                key="modal"
                className="c-modal-wrapper"
                initial="closed"
                animate="open"
                exit="closed"
                variants={ModalWrapperVariants}>
                <motion.div className={"c-modal-overlay"}
                    onClick={onClose}
                    initial="closed"
                    animate="open"
                    variants={ModalOverlayVariants}>
                </motion.div>

                <motion.div className={"c-modal " + modalClassName}
                    {...props}
                    onClick={e => e.stopPropagation()}
                    initial="closed"
                    animate="open"
                    variants={ModalVariants}>
                    <button onClick={onClose} aria-label="Fermer la modale" className="c-modal__close u-pd-xs">
                        <ScaleUp delay={200}>
                            <span className="icon-CROIX"></span>
                        </ScaleUp>
                    </button>

                    {children}
                </motion.div>
            </motion.div>
         </AnimatePresence>
    )
}

const ModalRoot = () => {
    let { component } = useModal()
    return component || <></>
}


export { Modal as default, ModalRoot }
