import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useAnalytics } from "context/analytics"
import DefaultLink from "components/defaultLink"
import { ButtonShareModal } from "components/modals/share-modal"
import { useMobile } from 'utils/useMobile'

const ShareButtonNativeModal = ({ btnClassName, btnLabel, children, shareUrl, shareTitle, shareOptions, ...props }) => {
    const { track } = useAnalytics()
    const data = useStaticQuery(graphql`
        query {
            siteData: file(name: {eq: "site"}, sourceInstanceName: {eq: "site"}) {
                childContentYaml {
                    modals {
                        share {
                            site {
                                default_message
                            }
                        }
                    }
                }
            }
            site {
                siteMetadata {
                    title
                    siteUrl
                }
            }
        }
    `)
    const { default_message } = data.siteData.childContentYaml.modals.share.site
    const { title, siteUrl } = data.site.siteMetadata
    
    return (
        <DefaultLink className={btnClassName} {...props} onClick={e => {
            e.preventDefault()
            navigator.share({
                title,
                text: default_message,
                url: shareUrl || siteUrl,
            })
            .then(() => track('event', 'share', {
                'content_type': shareUrl === siteUrl ? "site" : "video",
                'item_id': shareUrl || siteUrl,
                'method': "in_app"
            }))
            .catch(_e => {})
        }}>{children}</DefaultLink>
    )
}

export const ShareButton = props => {
    const isMobile = useMobile()
    return typeof navigator !== "undefined" && "share" in navigator && isMobile ?
        <ShareButtonNativeModal {...props} />
        : <ButtonShareModal {...props} />
}

export default ShareButton