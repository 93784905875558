import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { navigate, globalHistory } from "@reach/router"
import { useCookies } from 'context/cookies'
import { useModal } from "context/modal"
import Modal from "components/modal"
import DefaultLink from "components/defaultLink"

export const ButtonCookiesModal = ({ btnClassName, children, shareUrl, shareTitle, ...props }) => {
    let { showModal } = useModal()
    return (
        <DefaultLink onClick={() => showModal(CookiesModal, { shareUrl: shareUrl, shareTitle: shareTitle }) } className={btnClassName} {...props}>
            {children}
        </DefaultLink>
    )
}

export const CookiesModal = ({ shareTitle, shareUrl, ...props }) => {
    const data = useStaticQuery(graphql`
        query {
            siteData: file(name: {eq: "site"}, sourceInstanceName: {eq: "site"}) {
                childContentYaml {
                    modals {
                        cookies {
                            title
                            text
                            accept_all_text
                            deny_all_text
                            customize_text
                            simplify_text
                            customize_title
                            accept_text
                            deny_text
                            save_text
                            site_service {
                                title
                                text
                            }
                            services {
                                title
                                name
                                text
                                required
                            }
                        }
                    }
                }
            }
        }
    `)
    const {
        title, text, accept_all_text, deny_all_text, customize_text, simplify_text,
        customize_title, accept_text, deny_text, save_text, site_service, services
    } = data.siteData.childContentYaml.modals.cookies

    const [cookies, setCookie, removeCookie] = useCookies()
    const { hideModal } = useModal()
    const [showCustomize, setShowCustomize] = React.useState(false)
    const toggleCustomize = () => setShowCustomize(c => !c)

    /**
     *  Cookies management.
     */
    const toggleService = name => cookies[service.name] === "true" ? deny(name) : allow(name)
    const allow = async (name) => {
        if (cookies[name] !== "true")
            await setCookie(name, "true", { path: '/' })
    }
    const deny = async (serviceName) => {
        if (typeof cookies[name] !== "undefined")
            await removeCookie(cookieName, { path: '/' })
    }
    const allowAll = async () => {
        await Promise.all(services.map(s => allow(s.name)))
        return save()
    }
    const denyAll = async () => {
        await Promise.all(services.map(s => deny(s.name)))
        return save()
    }
    const save = async () => {
        await setCookie('rf-gdpr-consent', "true", { path: '/' })
        hideModal()
        const { pathname, search, hash } = globalHistory.location
        navigate(`${pathname}${search}${hash}`, {replace: true})
    }

    return (
        <Modal onClose={hideModal} modalClassName={"c-modal--primary u-pd-l u-pd-xl@main " + (showCustomize ? "c-modal--xl" : "")} {...props}>
            {!showCustomize ? (
                <>
                    <h3 className="c-h5 u-fs-xl u-font-adjustment u-uppercase u-center u-mg-top-l u-mg-bottom-l">{title}</h3>
                    <div className="u-fs-s u-mg-bottom-l" dangerouslySetInnerHTML={{__html: text}}></div>

                    <div className="u-flex">
                        <button type="button" aria-label={accept_all_text} className="c-btn c-btn--secondary u-width-full u-mg-right-xs" onClick={allowAll}>
                            <span className="u-font-adjustment">{accept_all_text}</span>
                        </button>
                        <button type="button" aria-label={deny_all_text} className="c-btn c-btn--blue-dark u-width-full  u-mg-left-xs" onClick={denyAll}>
                            <span className="u-font-adjustment">{deny_all_text}</span>
                        </button>
                    </div>

                    <div className="u-pd-vt-m">
                        <button type="button" aria-label={customize_text} className="c-btn c-btn--outline-white u-width-full" onClick={() => toggleCustomize()}>
                            <span className="u-font-adjustment">{customize_text}</span>
                            <span className={"icon-FLECHE_CTA u-mg-left-s"}></span>
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <h5 className="c-h5 u-fs-xl u-font-adjustment u-uppercase u-center u-flex-0 u-mg-top-l u-mg-bottom-l">{customize_title}</h5>
                    <div className="u-flex-1 u-overflow-auto u-custom-scrollbar u-pd-bottom-xl u-pd-hz-s u-pd-hz-l@main" style={{ outline: 'red'}}>
                        {services && (
                            <>
                                <article key={'site'} className="u-flex u-flex-center-vt">
                                    <div className="u-flex-1">
                                        <h5 className="u-fs-l u-white u-bold u-uppercase u-mg-bottom-s">{site_service.title}</h5>
                                        <div className="u-white" dangerouslySetInnerHTML={{ __html: site_service.text}}></div>
                                    </div>
                                    <div className="u-flex-1 u-flex u-flex-center-hz">
                                        <div className="u-white u-bold u-fs-l">Requis</div>
                                    </div>
                                </article>

                                <hr className="u-mg-vt-xl" style={{ borderColor: "white"}} />

                                {services.map((service, index) => (
                                    <div key={index}>
                                        <article key={service.name} className="u-flex@main u-flex-center-vt">
                                            <div className="u-flex-1 u-mg-bottom-s u-mg-0@main">
                                                <h5 className="u-fs-l u-white u-bold u-uppercase u-mg-bottom-s">{service.title}</h5>
                                                <div className="u-white" dangerouslySetInnerHTML={{ __html: service.text}}></div>
                                            </div>
                                            <div className="u-flex-1 u-flex ">
                                                <button type="button"
                                                    aria-label={accept_text}
                                                    className={"c-btn u-width-full u-mg-right-m " + (cookies[service.name] === "true" ? "c-btn--secondary" : "c-btn--blue-dark")}
                                                    onClick={() => allow(service.name)}
                                                >
                                                    {accept_text}
                                                </button>
                                                <button type="button"
                                                    aria-label={accept_text}
                                                    className={"c-btn u-width-full " + (cookies[service.name] === "true" ? "c-btn--blue-dark" : "c-btn--secondary")}
                                                    onClick={() => deny(service.name)}
                                                >
                                                    {deny_text}
                                                </button>
                                            </div>

                                            <input type="checkbox" className={'u-hide'} value={cookies[service.name] === "true"} onChange={() => toggleService(service.name)} />
                                        </article>

                                        {index < services.length - 1 &&
                                            <hr className="u-mg-vt-xl" style={{ borderColor: "white"}} />
                                        }
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                    <div className="u-flex u-flex-0 u-flex-center-vt u-flex-center-hz u-pd-top-l">
                        <button type="button" aria-label={simplify_text} className="c-btn c-btn--outline-white u-pd-hz-l u-mg-hz-s" onClick={() => toggleCustomize()}>
                            <span className={"icon-FLECHE_CTA u-missing-arrow-back u-mg-right-s"}></span>
                            <span className="u-font-adjustment">{simplify_text}</span>
                        </button>
                        <button type="button" aria-label={save_text} className="c-btn c-btn--secondary u-pd-hz-l u-mg-hz-s" onClick={() => save()}>
                            <span className="u-font-adjustment">{save_text}</span>
                        </button>
                    </div>
                </>
            )}
        </Modal>
    )
}