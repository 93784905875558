import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { simpleHash } from "utils/hash"

export const QuizContext = React.createContext({
  questions: [],
  answers: [],
  profile: null,
  currentQuestion: 0,
  navigateTo: _index => {},
  answer: _hash => {},
  resolve: () => {},
  submit: _answers => {},
  reset: () => {}
})

export const useQuiz = () => React.useContext(QuizContext)

export const QuizProvider = ({ children }) => {
    const data = useStaticQuery(graphql`query {
        # Query profiles.
        profiles: allFile(filter: {sourceInstanceName: {eq: "profiles"}}) {
            nodes {
                id
                name
                childMarkdownRemark {
                    id
                    frontmatter {
                        slug
                        sendinblue_attribute
                        quiz {
                            answers {
                                answer
                                question
                            }
                        }
                    }
                }
            }
            totalCount
        }
        # Query quiz configuration.
        quiz: file(sourceInstanceName: {eq: "quiz"}, name: {eq: "quiz"}) {
            id
            childMarkdownRemark {
                html
                id
                frontmatter {
                    title
                    questions {
                        question
                    }
                    require_email
                }
            }
        }
    }`)

    const profiles = data.profiles.nodes.map(({ id, name, ...n }) => n && n.childMarkdownRemark && ({
        id, // File ID (not Markdown ID).
        name,
        slug: n.childMarkdownRemark.frontmatter.slug,
        answers: n.childMarkdownRemark.frontmatter.quiz.answers,
        sendinblue_attribute: n.childMarkdownRemark.frontmatter.sendinblue_attribute,
    })).filter(i => !!i)
    let quiz = {
        id: data.quiz.id,
        body: data.quiz.childMarkdownRemark.html,
        ...data.quiz.childMarkdownRemark.frontmatter
    }
    const questions = quiz.questions.map(q => ({
        ...q,
        mandatory: true, // @todo : Add to cms.
        answers: profiles.map((p, index) => {
            const match = p.answers && p.answers.find(pa => pa.question === q.question)
            return match && ({
                profile_id: p.id,
                hash: simpleHash(`${p.id}-${index}`),
                answer: match.answer
            })
        }).filter(i => !!i)
    }))
    const defaultAnswers = questions.map(({ question }) => ({ question, hash: "" }))

    // answers is actually an array of hashes.
    const [answers, setAnswers] = React.useState(defaultAnswers)
    const [profile, setProfile] = React.useState(null)
    const [currentQuestion, setCurrentQuestion] = React.useState(0)

    /**
     * Methods.
     */

    const navigateTo = index => setCurrentQuestion(index)

    // Save an answer.
    const answer = hash => {
        setAnswers(_answers => _answers.map((a, i) => i === currentQuestion ? { ...a, hash } : a))
    }

    // Compute and return profile.
    const resolve = React.useCallback(() => {
        // Expects answer to be an array of hashes.
        const counts = answers.reduce(
            (previous, answer, index) => {
                const _answer = questions[index].answers.find(a => answer.hash === a.hash)
                return _answer ? previous.map(p => p.profile.id === _answer.profile_id ? { ...p, score: p.score + 1 } : p) : previous
            },
            profiles.map(profile => ({ profile, score: 0 }))
        )
        const winner = counts.reduce(
            (previous, count) => count.score > previous.score ? count : previous,
            counts[0] // initial winner in case of draw.
        )
        return winner
    }, [answers])

    // Submit answers of the quiz and navigate to the results.
    const submit = React.useCallback(() => {
        const winner = resolve()
        setProfile(winner && winner.profile)
    })

    // Reset quiz.
    const reset = () => {
        setAnswers(defaultAnswers)
        setCurrentQuestion(0)
        setProfile(null)
    }

    return (
        <QuizContext.Provider value={{
            ...quiz,
            require_email: quiz.require_email || false,
            questions,
            answers,
            profile,
            currentQuestion,
            navigateTo,
            answer,
            resolve,
            submit,
            reset
        }}>{children}</QuizContext.Provider>
    )
}