import React from "react"
import { Link } from "gatsby"

const DefaultLink = ({ label, children, link, onClick, className }) => {
    // only external link (ie `https://test.com`) are string
    let isExternal = link && (typeof link === 'string') && link.startsWith('http')
    return !!onClick ? (
        <button onClick={onClick}
            type="button"
            className={(className || '')}>
            { children ?
                children :
                <span className="u-font-adjustment" dangerouslySetInnerHTML={{__html: label}}></span>
            }
        </button>
    ) : isExternal ? (
        <a href={link}
            className={(className || '')}
            target="_blank"
            rel="noopener noreferrer">
            { children ?
                children :
                <span className="u-font-adjustment" dangerouslySetInnerHTML={{__html: label}}></span>
            }
        </a>
    ) : (
        <Link
            to={link}
            replace={true}
            className={(className || '')}>
            { children ?
                children :
                <span className="u-font-adjustment" dangerouslySetInnerHTML={{__html: label}}></span>
            }
        </Link>
    )
}

export default DefaultLink
