import React from 'react'
import { CookiesProvider as ReactCookiesProvider, useCookies as useReactCookies } from 'react-cookie/es6'
import { CookiesModal } from "components/modals/cookies-modal"
import { useModal } from "context/modal"

export const useCookies = params => useReactCookies(params)

export const CookiesInitializer = () => {
    const { showModal } = useModal()
    const [cookies] = useCookies(['rf-gdpr-consent'])
    
    React.useEffect(() => {
        if (cookies && cookies['rf-gdpr-consent'] !== "true") {
            showModal(CookiesModal)
        }
    }, [cookies])
    return <></>
}

export const CookiesProvider = ({ children }) => (
    <ReactCookiesProvider>
        <CookiesInitializer />
        {children}
    </ReactCookiesProvider>
)