import React from 'react'

let motion = {
    div: ({ children, ...props }) => <div {...props}>{children}</div>,
    main: ({ children, ...props }) => <main {...props}>{children}</main>,
}

let AnimatePresence = ({ children }) => <>{children}</>

// if not IE.
if (typeof window !== "undefined" && typeof window.MSStream === "undefined") {
    // Dynamically import framer-motion.
    const { motion: FMmotion, AnimatePresence: FMAnimatePresence } = require('framer-motion')
    if (typeof FMmotion !== "undefined" && typeof FMAnimatePresence !== "undefined") {
        motion = FMmotion
        AnimatePresence = FMAnimatePresence
    }
}

export {
    motion,
    AnimatePresence
}