import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

export const AnalyticsContext = React.createContext({
    track: () => {}
})

export const AnalyticsProvider = ({ children }) => {
    const data = useStaticQuery(graphql`query {
        site {
            siteMetadata {
                analytics {
                    googleTagManager {
                        key
                    }
                }
            }
        }
    }`)
    const { analytics } = data.site.siteMetadata

    /**
     *  Track calls gtag if google analytics is enabled.
     */
    const track = (...args) => {
        if (typeof window !== "undefined" && "gtag" in window)
            window.gtag(...args)
    }

    return (
        <AnalyticsContext.Provider value={{ track }}>
            <Helmet>
                {/* Google Tag Manager */}
                <script key={`rf-tag-google-tagmanager`}>{`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${analytics.googleTagManager.key}');
                `}</script>
            </Helmet>
            {children}
            {/* Google Tag Manager */}
            <noscript
                key={`rf-tag-google-tagmanager`}
                dangerouslySetInnerHTML={{ __html: `
                    <iframe
                        src="https://www.googletagmanager.com/ns.html?id=${analytics.googleTagManager.key}"
                        height="0" width="0" style="display:none;visibility:hidden"
                    ></iframe>
                ` }}
            ></noscript>
        </AnalyticsContext.Provider>
    )
}

export const useAnalytics = () => React.useContext(AnalyticsContext)

export default AnalyticsProvider