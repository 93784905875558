import React from 'react'
import { ModalProvider } from "context/modal"
import { CookiesProvider } from "context/cookies"
import { QuizProvider } from "context/quiz"
import { AnalyticsProvider } from "context/analytics"
import { ModalRoot } from "components/modal"
import "styles/main.scss"

export const Page = ({ children }) => {
    return (
        <QuizProvider>
            <ModalProvider>
                <CookiesProvider>
                    <AnalyticsProvider>
                        <>{children}</>
                        <ModalRoot />
                    </AnalyticsProvider>
                </CookiesProvider>
            </ModalProvider>
        </QuizProvider>
    )
}

export default Page