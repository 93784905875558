Math.imul = Math.imul || function(a, b) {
    var aHi = (a >>> 16) & 0xffff;
    var aLo = a & 0xffff;
    var bHi = (b >>> 16) & 0xffff;
    var bLo = b & 0xffff;
    // the shift by 0 fixes the sign on the high part
    // the final |0 converts the unsigned value into a signed value
    return ((aLo * bLo) + (((aHi * bLo + aLo * bHi) << 16) >>> 0) | 0);
}

exports.simpleHash = s => {
    for(var i = 0, h = 0xa600d60d; i < s.length; i++)
        h = Math.imul(h ^ s.charCodeAt(i), 2654435761)
    return ""+((h ^ (h >>> 16)) >>> 0)
}