import React from 'react'

const ModalContext = React.createContext({
    component: null,
    props: {},
    showModal: () => {},
    hideModal: () => {}
})

const useModal = () => React.useContext(ModalContext)

const ModalProvider = ({ children, ...props}) => {
    const [component, setComponent] = React.useState(null)

    const showModal = (_component, _componentProps) => {
        setComponent(React.createElement(_component, _componentProps))
        // Side effect
        document && document.querySelector('body').classList.add("u-overflow-hidden")
    }
    const hideModal = () => {
        setComponent(null)
        // Side effect
        document && document.querySelector('body').classList.remove("u-overflow-hidden")
    }

    return (
        <ModalContext.Provider value={{
            component, showModal, hideModal
        }}>
            {children}
        </ModalContext.Provider>
    )
}

export { useModal, ModalProvider }