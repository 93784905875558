import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useModal } from "context/modal"
import { useAnalytics } from "context/analytics"
import Modal from "components/modal"
import DefaultLink from "components/defaultLink"
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    EmailShareButton,
  } from 'react-share'
import copy from 'copy-to-clipboard'


const ButtonShareModal = ({ btnClassName, btnLabel, children, shareUrl, shareTitle, shareOptions, ...props }) => {
    let { showModal } = useModal()
    return (
        <DefaultLink onClick={() => showModal(ShareModal, { shareUrl, shareTitle, shareOptions })} className={btnClassName} {...props}>
            {children}
        </DefaultLink>
    )
}

const ShareModal = ({ shareTitle, shareUrl, shareOptions, ...props }) => {
    let { hideModal } = useModal()
    const { track } = useAnalytics()
    const data = useStaticQuery(graphql`
        query QueryShareModal {
            allContentJson {
                edges {
                    node {
                        global {
                            header {
                                share {
                                    title
                                    copied
                                }
                            }
                            social {
                                facebook {
                                    name
                                    icon
                                    url
                                    shortUrl
                                }
                                twitter {
                                    name
                                    icon
                                    url
                                    shortUrl
                                }
                                instagram {
                                    name
                                    icon
                                    url
                                    shortUrl
                                }
                                linkedin {
                                    name
                                    icon
                                    url
                                    shortUrl
                                }
                                mail {
                                    name
                                    icon
                                }
                                copy {
                                    name
                                    icon
                                }
                            }
                        }
                    }
                }
            }
            site {
                siteMetadata {
                    siteUrl
                    title
                }
            }
            siteData: file(name: {eq: "site"}, sourceInstanceName: {eq: "site"}) {
                childContentYaml {
                    modals {
                        share {
                            site {
                                default_message
                                facebook_message
                                twitter_message
                                linkedin_message
                                email_subject
                                email_message
                            }
                        }
                    }
                }
            }
        }
    `)
    const { global } = data.allContentJson.edges[0].node
    const content = global.header.share
    const social = data.allContentJson.edges[0].node.global.social
    const { share } = data.siteData.childContentYaml.modals
    const { title, siteUrl } = data.site.siteMetadata
    // Share messages.
    const default_message = shareOptions?.default_message || share?.site?.default_message
    const facebook_message = shareOptions?.facebook_message || share?.site?.facebook_message || default_message
    const twitter_message = shareOptions?.twitter_message || share?.site?.twitter_message || default_message
    const linkedin_message = shareOptions?.linkedin_message || share?.site?.linkedin_message || default_message
    const email_subject = shareOptions?.email_subject || share?.site?.email_subject || default_message
    const email_message = shareOptions?.email_message || share?.site?.email_message

    // Manage copy to clipboard
    const [isCopied, setIsCopied] = React.useState(false)

    React.useEffect(() => {
        let timeout
        if (isCopied) {
            timeout = setTimeout(() => setIsCopied(false), 3000)
        }
        return () => {
            clearTimeout(timeout)
        }
    }, [isCopied])

    const copyToClipboard = (text) => {
        copy(text.toString())
        setIsCopied(true)
    }
    
    const handleShare = service => {
        track('event', 'share', {
            'content_type': shareUrl === siteUrl ? "site" : "video",
            'item_id': shareUrl || siteUrl,
            'method': service
        })
    }

    return (
        <Modal onClose={hideModal} modalClassName={"c-modal--primary c-share-modal u-pd-l u-pd-xl@main"} {...props}>
            <h4 className="c-h5 u-fs-xl u-font-adjustment u-uppercase u-center u-mg-top-l u-mg-bottom-m">
                { shareTitle || content.title}
            </h4>

            <ul className={"c-share-modal__list u-reset-list u-pd-vt-l"}>
                <li className="u-flex u-mg-hz-m u-mg-bottom-m">
                    <FacebookShareButton
                        url={shareUrl || siteUrl}
                        quote={facebook_message}
                        className="u-pd-hz-s"
                        beforeOnClick={() => handleShare('facebook')}
                    >
                        <span className={"u-secondary " + social.facebook.icon}></span>
                    </FacebookShareButton>
                </li>
                <li className="u-flex u-mg-hz-m u-mg-bottom-m">
                    <TwitterShareButton
                        url={shareUrl || siteUrl}
                        title={twitter_message}
                        className="u-pd-hz-s"
                        beforeOnClick={() => handleShare('twitter')}
                    >
                        <span className={"u-secondary " + social.twitter.icon}></span>
                    </TwitterShareButton>
                </li>
                <li className="u-flex u-mg-hz-m u-mg-bottom-m">
                    <LinkedinShareButton
                        url={shareUrl || siteUrl}
                        title={title}
                        summary={linkedin_message}
                        className="u-pd-hz-s"
                        beforeOnClick={() => handleShare('linkedin')}
                    >
                        <span className={"u-secondary " + social.linkedin.icon}></span>
                    </LinkedinShareButton>
                </li>
                <li className="u-flex u-mg-hz-m u-mg-bottom-m">
                    <EmailShareButton
                        url={shareUrl || siteUrl}
                        subject={email_subject}
                        body={email_message}
                        separator={"\n"}
                        className="u-pd-hz-s"
                        beforeOnClick={() => handleShare('email')}
                    >
                        <span className={"u-secondary " + social.mail.icon} style={{ transform: 'scale(0.8)' }}></span>
                    </EmailShareButton>
                </li>
                <li className="u-flex u-relative u-mg-hz-m u-mg-bottom-m">
                    <button type="button"
                        aria-label={social.copy.name}
                        className="u-pd-hz-s"
                        onClick={() => {
                            copyToClipboard(shareUrl || siteUrl)
                            handleShare('copy-pasted')
                        }}
                    >
                        <span className={"u-secondary " + social.copy.icon}></span>
                    </button>
                    {isCopied && <span className="c-share-modal__message">{content.copied}</span>}
                </li>
            </ul>
        </Modal>
    )
}

export { ButtonShareModal, ShareModal }

